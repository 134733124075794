<!-- Footer -->
<footer class="footer page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col">

        <h5 class="text-uppercase footer-title">A contact ledger provided by:</h5>

        <div class="dokicall" (click)="clickLogo()">
            <div class="dokicall-logo-container">
              <img [src]="'../../../assets/media/logos/dokicall_logo_100px.png'" class="dokicall-logo"> 
            </div>
            <span>dokicall</span>
        </div>
      </div>

      <!-- Grid column -->
      <div class="col">
        <ul class="list-unstyled privacy-terms-list">
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms">Terms of Service</a>
          </li>
        </ul>
      </div>

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2021 Copyright:
    <a href="https://www.dokicall.com/">www.dokicall.com</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->