import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {

  public backClickHandler: () => void;
  public _showBackClick: boolean;

  constructor() { }

  public setBackClickHandler(handler: () => void): void {
    this.backClickHandler = handler;
  }

  get showBackClick(): boolean {
    return this._showBackClick;
  }

  set showBackClick(value: boolean) {
    this._showBackClick = value;
  }

  public backClick() {
    this.backClickHandler();
  }
}