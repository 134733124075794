<div class="item-list-container">
    <div class="search-container" *ngIf="showSearch">
        <div class="search-input-container">
            <input matInput type="text" [(ngModel)]='searchTerm' name='searchTerm' (change)="onSearch()" class="search-input"/>
            <div *ngIf="searchTerm" class="clear-button" (click)="clearSearch()">
                <span class="iconify" data-icon="mdi:close"></span>
            </div>
        </div>
        <div class="search-button-container" (click)="onSearch()">
            <span class="iconify" data-icon="mdi:magnify"></span>
        </div>
    </div>
    <mat-divider *ngIf="showSearch" class="horizontal-divider"></mat-divider>
    <div class="item-list">
        <div *ngFor="let item of displayItems" #itemCard class="list-item" (click)="selectItem(item)" [class]="item.style" [value]="item.id">
            <div class="item-container">
                <div class="item-name-container">
                    <div *ngIf="isItemAHospital(item)" class="favorite-contianer" (click)="toggleFavorite($event, item)">
                        <div *ngIf="!item?.favorite" class="favorite-star">
                            <span class="iconify" data-icon="mdi:star-outline"></span>
                        </div>
                        <div *ngIf="item?.favorite" class="favorite-star favorite-item">
                            <span class="iconify" data-icon="mdi:star"></span>
                        </div>
                    </div>
                    <span class="item-name">{{item.name}}</span>
                </div>
                
                <div *ngIf="!item?.extension" class="item-icon-container">
                    <span class="iconify" data-icon="mdi:chevron-right"></span>
                </div>
                <div *ngIf="item?.extension" class="item-extension-container">
                    <span  class="item-extension">{{item?.extension}}</span>
                    <div *ngIf="!isExtensionCopied(item)">
                        <span class="iconify item-extension-copy-icon" data-icon="mdi:content-copy"></span>
                    </div>
                    <div *ngIf="isExtensionCopied(item)" class="item-extension-copy-message">
                        <span>copied!</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!displayItems || !(displayItems?.length > 0)" class="empty-list-message-container">
            <span *ngIf="!searchTerm" class="empty-list-message">No items found for this category.</span>
            <span *ngIf="searchTerm" class="empty-list-message">No items found for this search term.</span>
        </div>
    </div>
</div>