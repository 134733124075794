<div class="main-screen">
    <div *ngIf="!hospitalService.isZoneSelected()" class="item-list">
        <breadcrumbs></breadcrumbs>
        <item-list
            [items]="zones"
            [showSearch]="true"
            (itemSelected)="selectItem($event)">
        </item-list>
    </div>
    <div *ngIf="hospitalService.isZoneSelected()">
        <router-outlet></router-outlet>
    </div>
</div>