import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { Subject } from 'rxjs';
import { Department, Hospital, Zone } from '../models/hospitals/hospitals.model';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root',
})
export class HospitalService {

    public _selectedHospital: Hospital = this.emptyHospital();
    public _selectedZone: Zone = this.emptyZone();
    public _hospitals: Hospital[] = [];

    private emitBreadcrumbChangeSource = new Subject<any>();
    public breadcrumbChangeEmitted$ = this.emitBreadcrumbChangeSource.asObservable();

    public localStorageFavoritesKey = 'favorite-hospitals';

    private hospitalDataURL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTTl5lpcWSTedBTAWs3aB9kMDQIR-cDc9eOBdBQ6qJxgZ7MDPoCWyEXyIW38W4ZKQYlJqlf3XQOeb-F/pub?gid=0&single=true&output=csv';

    constructor() {
    }

    get selectedHospital(): Hospital {
        return this._selectedHospital;
    }
    set selectedHospital(value: Hospital) {
        if (!value) {
            this._selectedHospital = this.emptyHospital();
            return;
        }
        this._selectedHospital.id = value.id;
        this._selectedHospital.favorite = value.favorite;
        this._selectedHospital.name = value.name;
        this._selectedHospital.zones.slice(0, this._selectedHospital.zones.length)
        this._selectedHospital.zones.push(...value.zones);
    }

    get selectedZone(): Zone {
        return this._selectedZone;
    }
    set selectedZone(value: Zone) {
        if (!value) {
            this._selectedZone = this.emptyZone();
            return;
        }
        this._selectedZone.id = value.id;
        this._selectedZone.name = value.name;
        this._selectedZone.departments.slice(0, this._selectedZone.departments.length)
        this._selectedZone.departments.push(...value.departments);
    }

    get hospitals(): Hospital[] {
        return this._hospitals;
    }
    set hospitals(value: Hospital[]) {
        this._hospitals = value;
    }

    public emptyHospital(): Hospital {
        return {
            id: null,
            favorite: false,
            name: '',
            zones: []
        }
    }

    public isHospitalSelected(): boolean {
        return this.selectedHospital.id !== null;
    }

    public emptyZone(): Zone {
        return {
            id: null,
            name: '',
            departments: []
        }
    }

    public isZoneSelected(): boolean {
        return this.selectedZone.id !== null;
    }

    public async initHospitals(): Promise<void> {
        if (this.hospitals.length > 0) {
            return;
        }
        const hospitalCSV = await this.getHospitalData();
        this.parseHospitalCSV(hospitalCSV);
    }

    private parseHospitalCSV(hospitalCSV: string): void {
        const lines = hospitalCSV.split('\n');
        for (let [ key, line ] of lines.entries()) {
            if (key === 0) {
                // table header
                continue;
            } else {
                const [ hospital, zone, department, extension ] = this.splitLine(line);
                if (this.isHospitalLineValid(hospital, zone, department, extension)) {
                    this.addNewExtension(hospital, zone, department, parseInt(extension));
                }
            }
        }
    }

    private isHospitalLineValid(hospital: string, zone: string, department: string, extension: string): boolean {
        if (!hospital || !zone || !department || !extension) {
             return false;
        }
        return true;
    }
    private splitLine(line: string): string[] {
        return line.split(',').map(value => value.replace('\r', '').trim());
    }

    private createNewHospital(hospital: string, zone: string, department: string, extension: number): Hospital {
        const newZone = this.createNewZone(zone, department, extension);
        const newId = this.encodeId(hospital);
        return {
            id: newId,
            name: hospital,
            zones: [
                newZone
            ]
        }
    }

    private createNewZone(zone: string, department: string, extension: number): Zone {
        const newDepartment = this.createNewDepartment(department, extension);
        const newId = this.encodeId(zone);
        return {
            id: newId,
            name: zone,
            departments: [
                newDepartment
            ]
        }
    }

    private createNewDepartment(department: string, extension: number): Department {
        const newId = this.encodeId(department);
        return {
            id: newId,
            name: department,
            extension: extension
        }
    }

    public encodeId(value: string): string {
        const md5 = new Md5();
        return `${md5.appendStr(value).end()}`;
    }

    private addNewExtension(hospital: string, zone: string, department: string, extension: number) {
        let existingHospital = this.hospitals?.find(existingHospital => existingHospital.name === hospital);
        if (!existingHospital) {
            this.hospitals.push(this.createNewHospital(hospital, zone, department, extension));
            return;
        }
        const existingZone = existingHospital?.zones?.find(existingZone => existingZone.name === zone);
        if (!existingZone) {
            existingHospital.zones.push(this.createNewZone(zone, department, extension))
            return;
        }
        
        const existingDepartmentExtension = existingZone?.departments?.find(existingDepartment => 
            existingDepartment.name === department 
            && existingDepartment.extension === extension
        );
        if (!existingDepartmentExtension) {
            existingZone.departments.push(this.createNewDepartment(department, extension))
        }
    }

    private async getHospitalData(): Promise<string> {
        const response = await axios.get(this.hospitalDataURL);
        return response.data;
    }

    public getUrlHospitalId(url: string): string {
        const urlParts = url.split('/')
        if (urlParts?.length > 1) {
            return urlParts[2];
        }
    }

    public getUrlZoneId(url: string): string {
        const urlParts = url.split('/')
        if (urlParts?.length > 3) {
            return urlParts[4];
        }
    }

    public navigateToHospitalSearch(router: Router): void {
        this._selectedHospital = this.emptyHospital();
        this._selectedZone = this.emptyZone();
        router.navigate([ 'hospital' ]);
    }

    public navigateToHospital(router: Router, hospitalId: string): void {
        this._selectedZone = this.emptyZone();
        router.navigate([ 'hospital', hospitalId, 'zone' ]);
    }

    public navigateToZone(router: Router, route: ActivatedRoute, zoneId: string): void {
        router.navigate([ `./${zoneId}/department`], { relativeTo: route });
    }

    // Service message commands
    public emitBreadcrumbChange(change: any) {
        this.emitBreadcrumbChangeSource.next(change);
    }

    // Local Storage

    public getFavoriteHospitals(): string[] {
        const currentFavoritesStr = localStorage.getItem(this.localStorageFavoritesKey);
        if (currentFavoritesStr) {
            return JSON.parse(currentFavoritesStr)
        }
        return [];
    }

    public setFavoriteHospitals(newFavorites: string[]): void {
        localStorage.setItem(this.localStorageFavoritesKey, JSON.stringify(newFavorites));
    }
}