import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToolbarService } from 'src/app/core/services/toolbar.service';

@Component({
  selector: 'dokicall-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

    constructor(
        public router: Router,
        public toolbarService: ToolbarService
    ) {}

    public backClick(): void {
        this.toolbarService.backClick();
    }

    public clickLogo(): void {
        this.router.navigate(['home']);
    }

    public openNewTab(url: string): void {
        window.open(url, '_blank');
      }
}
