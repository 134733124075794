<div class="breadcrumbs-container">
    <div (click)="hospitalSearch()" class="hospital-breadcrumb" [ngClass]="{ 'clickable': this.isHospitalsClickable() }">
        <span>Hospitals</span>
    </div>

    <div *ngIf="hospitalService.isHospitalSelected()">
        <span>/</span>
    </div>
    <div *ngIf="hospitalService.isHospitalSelected()" (click)="selectHospital()" class="hospital-breadcrumb" [ngClass]="{ 'clickable': this.isHospitalClickable() }">
        <span>{{ this.selectedHospital.name }}</span>
    </div>

    <div *ngIf="hospitalService.isZoneSelected()">
        <span>/</span>
    </div>
    <div *ngIf="hospitalService.isZoneSelected()">
        <span>{{ this.selectedZone.name }}</span>
    </div>
</div>