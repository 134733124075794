import { Component, OnInit } from '@angular/core';
import { HomeItem } from 'src/app/shared/models/home/home.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Hospital } from 'src/app/shared/models/hospitals/hospitals.model';
import { HospitalService } from 'src/app/shared/services/hospital.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public homeItems: HomeItem[] = [
    {
      id: '0',
      name: 'Search for Hospitals',
      link: 'hospital',
      style: 'hospitals-item-card'
    },
    {
      id: '1',
      name: 'General Contacts',
      link: 'general-contact',
      style: 'general-contacts-item-card'
    }
  ];
  public hospitals: Hospital[] = this.hospitalService.hospitals;
  public itemsToDisplay: (HomeItem | Hospital)[] = [...this.homeItems];
  public isLoading: boolean = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public hospitalService: HospitalService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    try {
      await this.hospitalService.initHospitals();
      this.loadFavorites();
    } finally {
      this.isLoading = false;
    }    
}

public loadFavorites(): void {
  const currentFavorites = this.hospitalService.getFavoriteHospitals();
  const favoriteHospitals = [];
  currentFavorites.forEach((favoriteId: string) => {
    const favoriteHospital = this.hospitals.find((hospital: Hospital) => hospital.id === favoriteId);
    if (favoriteHospital) {
      favoriteHospital.favorite = true;
      favoriteHospitals.push(favoriteHospital);
    }
  });
  this.itemsToDisplay.splice(0, this.itemsToDisplay.length);
  this.itemsToDisplay.push(...[...this.homeItems, ...favoriteHospitals]);
}

  public selectItem(item: HomeItem | Hospital): void {
    if ((item as Hospital)?.favorite) {
      const hospital = item as Hospital;
      this.hospitalService.navigateToHospital(this.router, hospital.id);
    } else {
      const homeItem = item as HomeItem;
      this.router.navigate([ `../${homeItem.link}` ], { relativeTo: this.route });
    }
  }
}
