import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Department } from 'src/app/shared/models/hospitals/hospitals.model';
import { HospitalService } from 'src/app/shared/services/hospital.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  public departments: Department[] = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public hospitalService: HospitalService
  ) {
  }

  public ngOnInit(): void {
    this.departments = this.hospitalService.selectedZone.departments;
  }

  public selectItem(item: Department): void {
    navigator.clipboard.writeText(`${item.extension}`);
  }
}
