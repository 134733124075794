<mat-progress-bar mode="indeterminate" [class.hidden]="!isLoading"></mat-progress-bar>
<div class="main-screen">
    <item-list
        [items]="itemsToDisplay"
        (itemSelected)="selectItem($event)">
    </item-list>


    <div class="sheets">

    </div>

    
</div>