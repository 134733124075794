export * from './core/components/privacy/privacy.component';
export * from './core/components/terms/terms.component';
export * from './core/components/footer/footer.component';
export * from './core/components/toolbar/toolbar.component';

export * from './shared/components/index';

export * from './components/general-contacts/general-contacts.component';
export * from './components/general-contacts/components/index';
export * from './components/home/home.component';
export * from './components/hospitals/hospitals.component';
export * from './components/hospitals/components/index';