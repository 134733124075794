import { Component, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Zone } from 'src/app/shared/models/hospitals/hospitals.model';
import { HospitalService } from 'src/app/shared/services/hospital.service';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {

  public zones: Zone[];
  public selectedZone: Zone;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    // On back button pressed
    setTimeout(() => this.loadData())
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public hospitalService: HospitalService
  ) {
    hospitalService.breadcrumbChangeEmitted$.subscribe(
      change => {
        this.selectedZone = undefined;
      }
    );
  }

  public ngOnInit(): void {
    this.loadData();
  }

  public async loadData(): Promise<void> {
    await this.hospitalService.initHospitals();

    const zoneId = this.hospitalService.getUrlZoneId(this.router.url);
    const hospitalId = this.hospitalService.getUrlHospitalId(this.router.url);
    const selectedHospital = this.hospitalService.hospitals.find(hospital => hospital.id === hospitalId);
    if (selectedHospital) {
      this.hospitalService.selectedHospital = selectedHospital;
      this.zones = [...selectedHospital.zones];
    } else {
      this.hospitalService.selectedHospital = undefined;
    }
    const selectedZone = this.zones.find(zone => zone.id === zoneId);
    if (selectedZone) {
      this.selectItem(selectedZone, false);
    } else {
      this.deselectZone();
    }
  }

  public deselectZone(): void {
    this.hospitalService.selectedZone = undefined;
  }

  public selectItem(zone: Zone, navigate: boolean = true): void {
    this.selectedZone = zone;
    this.hospitalService.selectedZone = this.selectedZone;
    if (navigate) {
      this.hospitalService.navigateToZone(this.router, this.route, zone.id);
    }    
  }

  public breadcrumbClicked(event: any): void {
    this.selectedZone = undefined;
  }
}
