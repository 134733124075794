import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HospitalService } from '../../services/hospital.service';

@Component({
  selector: 'item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit, OnChanges {

  @Input('items') public items: any[];
  @Input('showSearch') public showSearch: boolean;
  @Output() public itemSelected = new EventEmitter();

  public displayItems: any[]
  public searchTerm = '';

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public hospitalService: HospitalService
  ) { }

  public ngOnInit(): void {
    this.displayItems = this.items;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.displayItems = this.items;
      this.onSearch();
    }
  }

  public selectItem(item: any): void {
    this.items.forEach(element => {
      delete element.clicked;
    });
    item.clicked = true;
    this.itemSelected.emit(item);
  }

  public onSearch(): void {
    // TODO: Improve the search algorithm
    this.displayItems = this.items?.filter((item:any): boolean => {
      const nameMatch = item?.name?.toLowerCase()?.includes(this.searchTerm.toLowerCase());
      const extensionMatch = item?.extension?.toString()?.includes(this.searchTerm.toLowerCase());
      return nameMatch || extensionMatch;
    });
  }

  public clearSearch(): void {
    this.searchTerm = '';
    this.onSearch();
  }

  public isExtensionCopied(item: any): boolean {
    return item?.clicked;
  }

  public isItemAHospital(item: any): boolean {
    return !!item?.zones; 
  }

  public toggleFavorite(event: any, item: any): void {
    event.stopPropagation();
    const currentFavorites = this.hospitalService.getFavoriteHospitals();
    let newFavorites;
    if (item?.favorite) {
      item.favorite = false;
      newFavorites = currentFavorites?.length ? currentFavorites.filter((hospitalId: string) => hospitalId !== item?.id) : [];
    } else {
      item.favorite = true;
      newFavorites = currentFavorites?.length ? currentFavorites.concat(item?.id) : [ item?.id ];
    }
    this.hospitalService.setFavoriteHospitals(newFavorites);
  }
}
