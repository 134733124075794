<mat-progress-bar mode="indeterminate" [class.hidden]="!isLoading"></mat-progress-bar>
<div class="main-screen hospital-search-screen">
    <div *ngIf="!selectedRegion" class="item-list">
        <!-- <breadcrumbs></breadcrumbs> -->
        <item-list
            [items]="regions"
            [showSearch]="true"
            (itemSelected)="selectItem($event)">
        </item-list>
    </div>
    <div *ngIf="selectedRegion">
        <router-outlet></router-outlet>
    </div>
</div>