import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Region } from 'src/app/shared/models/general-contacts/general-contacts.model';
import { GeneralContactsService } from 'src/app/shared/services/general-contacts.service';
import { HospitalService } from 'src/app/shared/services/hospital.service';

@Component({
  selector: 'app-general-contacts',
  templateUrl: './general-contacts.component.html',
  styleUrls: ['./general-contacts.component.scss']
})
export class GeneralContactsComponent implements OnInit {

  public regions: Region[] = this.generalContactsService.regions;

  public selectedRegion: Region = null;
  public selectedRegionId: string;

  public isLoading: boolean = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public generalContactsService: GeneralContactsService,
    public hospitalService: HospitalService
  ) {
    generalContactsService.breadcrumbChangeEmitted$.subscribe(
      change => {
        if (!change) {
          this.selectedRegion = undefined;
          return;
        }
        // Ignore any hospital breadcrumb selection
        if (this.regions.some(region => change.id === region.id && change.name === region.name)) {
          return;
        }
        // If no hospital was selected then we want to show the list again
        this.selectedRegion = undefined;
      }
    );
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    try{
      await this.generalContactsService.initGeneralContacts();
      this.selectedRegionId = this.generalContactsService.getUrlRegionId(this.router.url);
      const selectedRegion = this.regions.find(region => region.id === this.selectedRegionId);
      if (selectedRegion) {
        this.selectItem(selectedRegion, false);
      } else {
        this.deselectHospital();
      }
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  }

  public deselectHospital(): void {
    this.generalContactsService.selectedRegion = undefined;
  }

  public selectItem(region: Region, navigate: boolean = true): void {
    this.selectedRegion = region;
    this.generalContactsService.selectedRegion = this.selectedRegion;
    if (navigate) {
      this.generalContactsService.navigateToRegion(this.router, this.route, region.id);
    }
  }

  public breadcrumbClicked(event: Region): void {
    // Ignore any region breadcrumb selection
    if (this.regions.some(region => event.id === region.id && event.name === region.name)) {
      return
    }
    // If no region was selected then we want to show the list again
    this.selectedRegion = undefined;
  }
}
