import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import * as components from './index';

const {
  ToolbarComponent,
  FooterComponent,
  PrivacyComponent,
  TermsComponent,
  ItemListComponent,
  BreadcrumbsComponent,
  HomeComponent,
  HospitalsComponent,
  ZonesComponent,
  DepartmentsComponent,
  GeneralContactsComponent,
  ContactsComponent
} = components;

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    FooterComponent,
    PrivacyComponent,
    TermsComponent,
    BreadcrumbsComponent,
    ItemListComponent,
    HomeComponent,
    HospitalsComponent,
    ZonesComponent,
    DepartmentsComponent,
    GeneralContactsComponent,
    ContactsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatToolbarModule,
    MatInputModule,
    MatDividerModule,
    MatProgressBarModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
