import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { Subject } from 'rxjs';
import { GeneralContact, Region } from '../models/general-contacts/general-contacts.model';
import { HospitalService } from './hospital.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralContactsService {

    public _selectedRegion: Region;
    public _regions: Region[] = [];

    private emitBreadcrumbChangeSource = new Subject<any>();
    public breadcrumbChangeEmitted$ = this.emitBreadcrumbChangeSource.asObservable();

    private generalContactsDataURL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTTl5lpcWSTedBTAWs3aB9kMDQIR-cDc9eOBdBQ6qJxgZ7MDPoCWyEXyIW38W4ZKQYlJqlf3XQOeb-F/pub?gid=1503529791&single=true&output=csv';

    constructor(public hospitalService: HospitalService) {
    }

    get selectedRegion(): Region {
        return this._selectedRegion;
    }
    set selectedRegion(value: Region) {
        this._selectedRegion = value;
    }

    get regions(): Region[] {
        return this._regions;
    }
    set regions(value: Region[]) {
        this._regions = value;
    }

    public async initGeneralContacts(): Promise<void> {
        if (this.regions.length > 0) {
            return;
        }
        const generalContactsCSV = await this.getGeneralContactsData();
        this.parseGeneralContactsCSV(generalContactsCSV);
    }

    private parseGeneralContactsCSV(generalContactsCSV: string): void {
        const lines = generalContactsCSV.split('\n');
        for (let [ key, line ] of lines.entries()) {
            if (key === 0) {
                // table header
                continue;
            }
            let [ region, name, extension ] = this.splitLine(line);
            // TODO: Add line validation
            const lineHasAllFields = region && name && extension !== undefined && extension !== null;
            let extensionIsValidNumber = false;
            try {
                const numberVersion = parseInt(extension)
                if (numberVersion || numberVersion === 0) {
                    extensionIsValidNumber = true;
                }
            } catch (e) {}

            if (!lineHasAllFields || !extensionIsValidNumber) {
                continue;
            }
            this.addNewGeneralContact(region, name, parseInt(extension));
        }
    }

    private splitLine(line: string): string[] {
        return line.split(',').map(value => value.replace('\r', '').trim());
    }

    private createNewRegion(region: string, name: string, extension: number): Region {
        const newGeneralContact = this.createNewGeneralContact(name, extension);
        const newId = this.hospitalService.encodeId(region);
        return {
            id: newId,
            name: region,
            contacts: [
                newGeneralContact
            ]
        }
    }

    private createNewGeneralContact(name: string, extension: number): GeneralContact {
        const newId = this.hospitalService.encodeId(name);
        return {
            id: newId,
            name,
            extension
        }
    }

    private addNewGeneralContact(region: string, name: string, extension: number) {
        let existingRegion = this.regions?.find(existingRegion => existingRegion.name === region);
        if (!existingRegion) {
            this.regions.push(this.createNewRegion(region, name, extension));
            return;
        }
        const existingZone = existingRegion?.contacts?.find(existingContact => existingContact.name === name && existingContact.extension === extension);
        if (!existingZone) {
            existingRegion.contacts.push(this.createNewGeneralContact(name, extension))
            return;
        }
    }

    private async getGeneralContactsData(): Promise<string> {
        const response = await axios.get(this.generalContactsDataURL);
        return response.data
    }

    public getUrlRegionId(url: string): string {
        const urlParts = url.split('/')
        if (urlParts?.length > 1) {
            return urlParts[2];
        }
    }

    public navigateToRegion(router: Router, route: ActivatedRoute, regionId: string): void {
        this._selectedRegion = undefined;
        router.navigate([ `./${regionId}/contact`], { relativeTo: route });
    }

    // Service message commands
    public emitBreadcrumbChange(change: any) {
        this.emitBreadcrumbChangeSource.next(change);
    }
}