import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as components from './index';

const {
  HomeComponent,
  TermsComponent,
  PrivacyComponent,
  HospitalsComponent,
  ZonesComponent,
  DepartmentsComponent,
  GeneralContactsComponent,
  ContactsComponent
} = components;

const routes: Routes = [
  
  // { path: 'soon', component: components.SoonComponent, pathMatch: 'full' },

  // Home
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },

  // Terms
  {
    path: 'terms',
    component: TermsComponent,
    pathMatch: 'full'
  },

  // Privacy
  {
    path: 'privacy',
    component: PrivacyComponent,
    pathMatch: 'full'
  },

  // Hospitals

  {
    path: 'hospital',
    component: HospitalsComponent,
    children: [
      {
        path: ':id/zone',
        component: ZonesComponent,
        children: [
          {
            path: ':id/department',
            component: DepartmentsComponent,
          }
        ]
      }
    ]
  },

  // General Contacts

  {
    path: 'general-contact',
    component: GeneralContactsComponent,
    children: [
      {
        path: ':id/contact',
        component: ContactsComponent
      }
    ]
  },

  {
    path: '**',
    redirectTo: '/home'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
