<mat-progress-bar mode="indeterminate" [class.hidden]="!isLoading"></mat-progress-bar>
<div class="main-screen hospital-search-screen">
    <div *ngIf="!hospitalService.isHospitalSelected()" class="item-list">
        <breadcrumbs></breadcrumbs>
        <item-list
            [items]="hospitals"
            [showSearch]="true"
            (itemSelected)="selectItem($event)">
        </item-list>
    </div>
    <div *ngIf="hospitalService.isHospitalSelected()">
        <router-outlet></router-outlet>
    </div>
</div>