import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Hospital } from 'src/app/shared/models/hospitals/hospitals.model';
import { HospitalService } from 'src/app/shared/services/hospital.service';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.scss']
})
export class HospitalsComponent implements OnInit {

  public hospitals: Hospital[] = this.hospitalService.hospitals;
  public selectedHospital: Hospital = null;
  public isLoading: boolean = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public hospitalService: HospitalService
  ) {
    hospitalService.breadcrumbChangeEmitted$.subscribe(
      change => {
        if (!change) {
          this.selectedHospital = undefined;
          return;
        }
        // Ignore any hospital breadcrumb selection
        if (this.hospitals.some(hospital => change.id === hospital.id && change.name === hospital.name)) {
          return;
        }
        // If no hospital was selected then we want to show the list again
        this.selectedHospital = undefined;
      }
    );
  }

  public async ngOnInit(): Promise<void> {
      this.isLoading = true;
      try {
        await this.hospitalService.initHospitals();
        const hospitalId = this.hospitalService.getUrlHospitalId(this.router.url);
        const selectedHospital = this.hospitals.find(hospital => hospital.id === hospitalId);
        if (selectedHospital) {
          this.selectItem(selectedHospital, false);
        } else {
          this.deselectHospital();
        }
        this.loadFavorites();
      } catch (e) {
        console.error('An error occurred:', e);
        throw e;
      } finally {
        this.isLoading = false;
      } 
  }

  public loadFavorites(): void {
    const currentFavorites = this.hospitalService.getFavoriteHospitals();
    currentFavorites.forEach((favoriteId: string) => {
      const favoriteHospital = this.hospitals.find((hospital: Hospital) => hospital.id === favoriteId);
      if (favoriteHospital) {
        favoriteHospital.favorite = true;
      }
    });
  }

  public deselectHospital(): void {
    this.hospitalService.selectedHospital = undefined;
    this.hospitalService.selectedZone = undefined;
  }

  public selectItem(hospital: Hospital, navigate: boolean = true): void {
    this.selectedHospital = hospital;
    this.hospitalService.selectedHospital = this.selectedHospital;
    if (navigate) {
      this.hospitalService.navigateToHospital(this.router, hospital.id);
    }
  }

  public breadcrumbClicked(event: Hospital): void {
    // Ignore any hospital breadcrumb selection
    if (this.hospitals.some(hospital => event.id === hospital.id && event.name === hospital.name)) {
      return
    }
    // If no hospital was selected then we want to show the list again
    this.selectedHospital = undefined;
  }
}
