import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralContact } from 'src/app/shared/models/general-contacts/general-contacts.model';
import { GeneralContactsService } from 'src/app/shared/services/general-contacts.service';
import { HospitalService } from 'src/app/shared/services/hospital.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  public contacts: GeneralContact[] = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public generalContactsService: GeneralContactsService,
    public hospitalService: HospitalService
  ) {
  }

  public ngOnInit(): void {
    const regionId = this.generalContactsService.getUrlRegionId(this.router.url);
    const region = this.generalContactsService.regions.find(region => region.id === regionId);
    this.contacts = region?.contacts;
  }

  public selectItem(item: GeneralContact): void {
    navigator.clipboard.writeText(`${item.extension}`);
  }
}
