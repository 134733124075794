import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Hospital, Zone } from '../../models/hospitals/hospitals.model';
import { HospitalService } from '../../services/hospital.service';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  public selectedHospital: Hospital;
  public selectedZone: Zone;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public hospitalService: HospitalService
  ) { }

  public ngOnInit(): void {
    this.selectedHospital = this.hospitalService._selectedHospital;
    this.selectedZone = this.hospitalService._selectedZone;
  }

  public selectHospital(): void {
    if (this.isHospitalClickable()) {
      this.hospitalService.emitBreadcrumbChange(this.selectedHospital);
      this.hospitalService.navigateToHospital(this.router, this.selectedHospital.id);
    }
  }

  public hospitalSearch(): void {
    if (this.isHospitalsClickable()) {
      this.hospitalService.emitBreadcrumbChange(null);
      this.hospitalService.navigateToHospitalSearch(this.router);
    }
  }

  public isHospitalsClickable(): boolean {
    return !!this.selectedHospital;
  }

  public isHospitalClickable(): boolean {
    return !!this.selectedZone;
  }
}
